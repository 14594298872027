const body = document.querySelector("body"),
	main = document.querySelector("main"),
	header = document.querySelector("header#main-header"),
	pageTitle = document.querySelector("header#main-header .page-title"),
	folderTitle = document.querySelector("header#main-header .folder-title"),
	grid = document.querySelector("#grid-card"),
	soundscape = document.querySelector("#soundscape"),
	aid = document.querySelector("#aid"),
	frame = document.querySelector("#frame"),
	secondary = document.querySelector("header#main-header .secondary"),
	table = document.querySelector("#table"),
	collection = document.querySelector("#collection"),
	collectionPreviewWrapper = document.querySelector("#collection .preview-wrapper"),
	shiftables = document.querySelectorAll(".shift"),
	transEnd = "transitionend webkitTransitionEnd",
	scrollInterval = null,
	scrollShift = 0;
let collections;

const setup = () => {

	if (grid) {
		setupDrag();
		loadCollection();
		setupGrid();
		loadImages(grid);
	}

	if (soundscape) {
		setupSoundscape();
	}

	$(window).on("popstate", browserNav);
	if (body.classList.contains("opened")) {
		const folder = document.querySelector(".folder"),
			slug = folder.dataset.slug,
			preview = grid.querySelector(`.preview[data-slug="${slug}"]`);

		history.replaceState({
			action: "up",
			slug: folder.dataset.slug
		}, document.title, window.location.href);
		scrollToPreview(preview);
		loadFolder(folder);
	}

	checkSize();

	body.classList.add("loaded");
	window.addEventListener("resize", onResize);
	window.addEventListener("mousemove", onMouseMove);
	window.addEventListener("scroll", onVertScroll);
	window.addEventListener("mousewheel", onHorzScroll);
};

const onMouseMove = (e) => {
	updateShiftables(e);
	if (soundscape) {
		updateAudioVolume(e);
	}
};

const onVertScroll = (e) => {
	updateHeader(this, e);
	if (soundscape) {
		updateAudioVolume(e);
	}
};

const onResize = () => {
	const resizables = document.querySelectorAll(".resizable");
	if (checkSize("phone")) {
		const textElems = resizables.querySelectorAll(".text");
		$(resizables).resizable("disable");
		textElems.forEach((textElem) => {
			textElem.style = "";
		});
	} else {
		$(resizables).resizable("enable");
	}
	if (grid) {
		resizeGrid();
	}
	resizeColumns();
	resizeCollection();
	resizeFrameBar();
}


const setupGrid = () => {
	const gutter = grid.querySelector(".gutter"),
		gutterWidth = gutter.getBoundingClientRect().width;

	$(grid).isotope({
		layoutMode: "masonryHorizontal",
		previewSelector: ".preview",
		percentPosition: false,
		gutter: gutterWidth,
		transitionDuration: 0,
		masonryHorizontal: {
			rowHeight: ".sizer",
			gutter: gutterWidth,
			percentPosition: true,
			transitionDuration: 0
		},
		animationOptions: {
			duration: 0
		}
	}).on("layoutComplete", () => {
		setTimeout(() => {
			resizeGrid();
		});
	});

	grid.classList.add("loaded");
	onVertScroll();
};

const resizeGrid = () => {
	const gutter = grid.querySelector(".gutter"),
		gutterWidth = gutter.getBoundingClientRect().width,
		gridWidth = grid.style.width;
	grid.style.minWidth = gridWidth;
	grid.style.flex = `0 0 ${gridWidth}`;
	// if(grid.dataset.isotope) {
	// 	const previews = grid.querySelectorAll(".preview");
	// 	previews.forEach((preview) => {
	// 		sizeImage(preview, gutterWidth);
	// 		grid.isotope("layout");
	// 	});
	// }
};

const loadImages = (parent) => {
	// if(!parent || !parent.length) {
	// 	parent = body;
	// }
	const imgsToLoad = body.querySelectorAll("img.load");
	imgsToLoad.forEach((img, i) => {
		const src = img.dataset.src,
			parent = $(img).parents(".preview, .folder")[0];
		if (parent) {
			parent.classList.add("has-loader");
			// const loader = parent.querySelector(".loader");
			// if(loader) {
			// 	parent.innerHTML+= `<div class="loader"></div>`;
			// }
			img.setAttribute("src", src);
			$.when(sizeImage(parent)).done(() => {
				if ($(parent).parents("#grid")) {
					resizeGrid();
				}
				img.classList.remove("load");
				parent.classList.add("show");
				$(parent).imagesLoaded(() => {
					parent.classList.add("loaded");
					img.style.width = "";
					img.style.height = "";
					img.style.maxHeight = "";
					parent.style.width = "";
					parent.style.height = "";
					parent.style.maxHeight = "";
					if ($(parent).parents("#table").length) {
						if (grid.classList.contains("loaded")) {
							resizeGrid();
						}
						setTimeout(function() {
							resizeCollection();
						}, 100);
					}
				});
			});
		}
	});
};

const sizeImage = (parent, gutter) => {
	return;
	const img = parent.querySelector("img"),
		imgWidth = img.dataset.width,
		imgHeight = img.dataset.height,
		ratio = imgWidth / imgHeight;
	if (!ratio) {
		return;
	}
	if (gutter) {
		if (parent.classList.contains("large")) {
			height = table.getBoundingClientRect().height - gutter * 2;
		}
		const width = height * ratio;
	} else {
		const padding = parseInt($(parent).parents(".block")[0].style.paddingLeft) * 2,
			parentWidth = $(parent).innerWidth() - padding,
			maxWidth = parseInt($(parent).parents(".block")[0].style.maxWidth);
		if (parentWidth > imgWidth) {
			const width = imgWidth;
		} else {
			const width = parentWidth;
		}
		if (width > maxWidth) {
			const width = maxWidth;
		}
		height = width / ratio;
	}
	preview.style.height = height;
	preview.style.maxHeight = height;

	img.style.height = height;
	img.style.maxHeight = height;

	if (!preview.classList.contains("show")) {
		preview.style.width = width + "px";
	} else {
		img.style.width = "";
	}
};

/*****************************
 ***********SCROLLERS**********
 *****************************/

/********VERT SCROLL*********/

const updateHeader = (self, e) => {
	const vertScrollTop = $(main).scrollTop();
	// NEEDS TO BE MORE DYNAMIC, DOES VERT SCROLL ELEM HAVE A CLASS?
	section = document.querySelector("#chapters");
	let paddingTop;

	if (section) {
		sectionTop = section.getBoundingClientRect().top;
		if (sectionTop <= 0) {
			header.classList.add("fixed");
			paddingTop = header.getBoundingClientRect().height;
		} else {
			header.classList.remove("fixed");
			paddingTop = 0;
		}
		section.style.paddingTop = `${paddingTop}px`;
	}
};

/********HORZ SCROLL*********/

const horzScroll = document.querySelector(".horz-scroll");

const onHorzScroll = (e) => {
	if (body.classList.contains("mobile")) {
		return;
	}
	if (!body.scrollLeft) {
		body.scrollLeft = 0;
	}
	if (grid) {
		if (!grid.classList.contains("no-scroll")) {
			updateStoryBegin();
		}
	}
	if (soundscape) {
		updateAudioVolume(e);
	}
	body.scrollLeft += e.deltaY;
};

if (horzScroll) {
	horzScroll.addEventListener("mousewheel", onHorzScroll);
}

/********CARD SCROLL*********/

const cardScroll = (card, e) => {
	const scrollable = card.scrollHeight > card.innerHeight;
	if (scrollable) {
		const forward = e.deltaY < 0,
			right = card.getBoundingClientRect().left + card.innerWidth,
			hitBottom = $card[0].scrollHeight - $(card).scrollTop() === card.innerHeight;
		if (right <= window.innerWidth) {
			if (!hitBottom) {
				grid.classList.add("no-scroll");
				card.classList.add("responsible");
			}
			if (hitBottom || $(card).scrollTop() === 0) {
				grid.classList.remove("no-scroll");
				card.classList.remove("responsible");
			}
		} else {
			return e.preventDefault();
		}
	}
};

const cards = document.querySelectorAll(".card");
cards.forEach((card) => {
	card.addEventListener("mousewheel", (e) => {
		cardScroll(this, e);
	});
	card.addEventListener("mouseleave", (e) => {
		grid.classList.remove("no-scroll");
	});
});

/********SCROLL TO BUTTONS*********/

const clickScrollToButton = (scrollToButton) => {
	const href = scrollToButton.getAttribute("href"),
		target = document.querySelector(href);
	if (target) {
		const bounds = target.getBoundingClientRect(),
			args = {
				behavior: "smooth"
			};
		if (scrollToButton.classList.contains("down")) {
			args.top = bounds.top;
		}
		if (scrollToButton.classList.contains("right")) {
			args.left = bounds.left;
		}
		body.scrollTo(args);
	}
};

const scrollToButton = document.querySelector("a.scroll-to");
if (scrollToButton) {
	scrollToButton.addEventListener("click", (e) => {
		e.preventDefault();
		clickScrollToButton(scrollToButton);
	});
}


/*****************************
 **********SOUNDSCAPE**********
 *****************************/

const markers = document.querySelectorAll(".marker"),
	panners = [];
let AudioContext, audioCtx, audioListener;

const setupSoundscape = () => {
	AudioContext = window.AudioContext || window.webkitAudioContext;
	audioCtx = new AudioContext();
	audioListener = audioCtx.listener;
	// if(markers)
	markers.forEach((marker) => {
		const markerBounds = marker.getBoundingClientRect(),
			markerX = markerBounds.x,
			markerY = markerBounds.y,
			markerZ = 300;

		const panner = new PannerNode(audioCtx, {
			panningModel: "HRTF",
			distanceModel: "linear",
			positionX: markerX,
			positionY: markerY,
			positionZ: markerZ,
			orientationX: 0,
			orientationY: 0,
			orientationZ: -1,
			refDistance: 1,
			maxDistance: 500,
			rolloffFactor: 100,
			coneInnerAngle: 60,
			coneOuterAngle: 90,
			coneOuterGain: .3
		});

		const audioElement = marker.querySelector("audio"),
			track = audioCtx.createMediaElementSource(audioElement);
		track.connect(panner).connect(audioCtx.destination);
		panners.push(panner);
	});

	const beginSoundButton = document.querySelector("#soundscape-begin");
	if (beginSoundButton) {
		beginSoundButton.addEventListener("click", (e) => {
			body.classList.add("playing");
			markers.forEach((marker) => {
				const audioElement = marker.querySelector("audio");
				if (audioCtx.state === 'suspended') {
					audioCtx.resume();
					audioElement.play();
				}
			});
		});
	}
}

const updateAudioVolume = (e) => {
	if (e) {
		audioListener.positionX.value = e.pageX + body.scrollLeft;
		audioListener.positionY.value = e.pageY + body.scrollTop;
	}
	// panners.forEach((panner, i) => {
	// 	const marker = markers[i],
	// 				box = marker.getBoundingClientRect();
	// 	if(i === 0 && e) {

	// 	}
	// 	panner.positionX.value = box.x;
	// 	panner.positionY.value = box.y;
	// });

	// markers.forEach((marker, i) => {
	// 	const audio = marker.querySelector("audio");
	// });
}

const clickAudioButton = () => {
	soundscape.classList.toggle("muted");
	markers.forEach((marker) => {
		const audioElement = marker.querySelector("audio");
		if (soundscape.classList.contains("muted")) {
			audioCtx.suspend();
			audioElement.pause();
		} else {
			audioCtx.resume();
			audioElement.play();
		}
	});
};

const audioButton = document.querySelector("button.audio");
if (audioButton) {
	audioButton.addEventListener("click", () => {
		clickAudioButton();
	});
}

/*****************************
 **************GRID************
 *****************************/

const setupDrag = () => {
	$(grid).find(".preview").draggable({
		containment: false,
		helper: "clone",
		// connectToSortable: "#collection",
		snap: "#collection",
		snapMode: "inner",
		snapTolerance: 0,
		appendTo: "body",
		scroll: false,
		cursorAt: {
			left: 0,
			top: 0
		},
		start: (e, ui) => {
			const helper = ui.helper[0];
			helper.classList.remove("prime");
			helper.classList.add("clone");
			grid.classList.add("dragging");
		},
		drag: (e, ui) => {
			const helper = ui.helper[0],
				helperBounds = helper.getBoundingClientRect(),
				x = -helperBounds.width / 2 + body.scrollLeft,
				y = -helperBounds.height / 2;
			helper.style.transform = `translate3d(${x}px, ${y}px, 0)`;
			const previewTop = ui.offset.top,
				collectionTop = collectionPreviewWrapper.getBoundingClientRect().top;
			if (previewTop >= collectionTop - 1) {
				helper.classList.add("over");
			} else {
				helper.classList.remove("over");
			}
		},
		stop: (e, ui) => {
			const helper = ui.helper[0];
			// helper.classList.remove("helper");
			grid.classList.remove("dragging");
		}
	});

	$(collection).droppable({
		accept: ".preview.droppable",
		drop: (e, ui) => {
			collection.classList.remove("over");
			const preview = ui.draggable[0].dataset.slug;
			collectItem(preview, true);
		},
		over: (e, ui) => {
			collection.classList.add("over");
		},
		out: (e, ui) => {
			collection.classList.remove("over");
		}
	});

	$("#table, .folder").droppable({
		accept: ".preview",
		drop: (e, ui) => {
			return false;
			const helper = ui.helper[0];
			if (helper.classList.contains("deletable")) {
				const preview = helper.dataset.slug;
				uncollectItem(preview);
			}
		},
		over: (e, ui) => {
			return false;
			const helper = ui.helper[0],
				placeholder = document.querySelector("#collection .placeholder");
			helper.classList.add("deleting");
			if (placeholder) {
				placeholder.classList.add("hide");
			}
		},
		out: (e, ui) => {
			return false;
			const helper = ui.helper[0],
				placeholder = document.querySelector("#collection .placeholder");
			helper.classList.remove("deleting");
			if (placeholder) {
				placeholder.classList.remove("hide");
			}
		}
	});

	$(collectionPreviewWrapper).sortable({
		items: "> .preview",
		containment: false,
		helper: "clone",
		snap: ".preview-wrapper",
		snapMode: "inner",
		snapTolerance: 0,
		// scroll: true,
		// revert: true,
		placeholder: "placeholder",
		forcePlaceholderSize: true,
		// cursorAt: {
		// 	left: 0,
		// 	top: 0
		// },
		start: (e, ui) => {
			const helper = ui.helper[0],
				helperBounds = helper.getBoundingClientRect();
			if (ui.offset) {
				const x = body.scrollLeft,
					y = 0;
				// y = -helperBounds.height / 2
				helper.style.transform = `translate3d(${x}px, ${y}px, 0)`;
			}
			// helper.style.left = ui.position.left + body.scrollLeft;

			helper.classList.add("deletable", "sorting");
			collection.classList.add("sorting");
		},
		stop: (e, ui) => {
			const preview = ui.item[0];
			preview.classList.remove("sorting");
			collection.classList.remove("sorting");
			// chapterSlug = ui.helper.dataset.chapter;
			// $(collectionPreviewWrapper).sortable( "refresh" );
			saveCollection();
		}
	});
};

const collectItem = (slug, track) => {
	const preview = grid.querySelector(`[data-slug="${slug}"]`).cloneNode(true),
		collected = collectionPreviewWrapper.querySelector(`[data-slug="${slug}"]`);
	preview.classList.remove("prime", "shift", "rotate", "droppable");
	preview.classList.add("collected", "clone");
	preview.setAttribute("style", "");
	let img = preview.querySelector("img");
	// if(img) {
	// 	const thumb = preview.dataset.thumb;
	// 	img.setAttribute("style", "");
	// 	img.setAttribute("data-src", thumb);
	// 	img.setAttribute("src", thumb);
	// }
	if (collected) {
		collected.parentNode.appendChild(collected);
		if (!collected.classList.add("selected")) {
			collected.classList.add("selected");
			setTimeout(() => {
				collected.classList.remove("selected");
			}, 1000);
		}
	} else {
		const chapterSlug = preview.dataset.chapter;
		if (track) {
			ga("send", "e", "preview", "collect", chapterSlug, slug);
		}
		collection.classList.remove("empty");
		collectionPreviewWrapper.appendChild(preview);
		resizeCollection();
		saveCollection();
		resizeCollection();
		$(preview).imagesLoaded(() => {
			setTimeout(() => {
				resizeCollection();
			}, 100);
		});
		if (body.classList.contains("collection") && preview.classList.contains("hide")) {
			const previewToShow = grid.querySelector(`[data-slug="${slug}"]`);
			if (previewToShow) {
				previewToShow.classList.remove("hide");
			}
			$(grid).isotope("layout");
		}
	}
};

const uncollectItem = (slug) => {
	const preview = collection.querySelector(`[data-slug="${slug}"]`),
		chapter = preview.dataset.chapter;
	preview.remove();
	setTimeout(function() {
		removeCollectedItem(chapter, slug);
		if (!collection.querySelectorAll(".preview").length) {
			collection.classList.add("empty");
		}
	}, 10);
	if (body.classList.contains("collection")) {
		const previewToHide = grid.querySelector(`[data-slug="${slug}"]`);
		if (previewToHide) {
			previewToHide.classList.add("hide");
		}
		$(grid).isotope("layout");
	}
};

const clickPreview = (preview) => {
	const slug = preview.dataset.slug;

	if (body.classList.contains("opened")) {
		scrollToPreview(preview);
	}

	if (preview.classList.contains("selected")) {
		const folder = document.querySelector(`.folder[data-slug="${slug}"]`);
		if ($(folder).is(":last-of-type")) {
			closeFolder(slug);
		}
		folder.classList.add("swap");
		main.appendChild(folder);
		setTimeout(function() {
			folder.classList.remove("swap");
		}, 100);
	} else if (preview.classList.contains("collected") && body.classList.contains("opened")) {
		openFolder(slug, true, true);
	} else if (!body.classList.contains("opened")) {
		openFolder(slug, true, true);
	}
};

const previews = document.querySelectorAll(".preview");
previews.forEach((preview) => {
	preview.addEventListener("click", (e) => {
		if (grid) {
			e.preventDefault();
			clickPreview(preview);
		}
	});
});

const paginate = (e) => {
	e.preventDefault();
	const arrow = e.target,
		slug = arrow.dataset.slug,
		preview = grid.querySelector(`.preview[data-slug="${slug}"]`);

	if (preview) {
		if (body.classList.contains("opened")) {
			scrollToPreview(preview);
		}
		openFolder(slug, true, false);
	}
};

const setupPagination = () => {
	const paginateButtons = document.querySelectorAll(".folder .paginate");
	paginateButtons.forEach((paginateButton) => {
		paginateButton.addEventListener("click", paginate);
	});
}

const openDur = 600,
	closeDur = 350;
const openFolder = (slug, push, over) => {
	const preview = grid.querySelector(`.preview[data-slug="${slug}"]`),
		index = preview.dataset.index,
		url = preview.getAttribute("href"),
		title = preview.dataset.title,
		chapterSlug = preview.dataset.chapter,
		selectedItem = collection.querySelector(".selected"),
		collectedPreview = collection.querySelector(`[data-slug="${slug}"]`);

	if (selectedItem) {
		selectedItem.classList.remove("selected");
	}
	if (collectedPreview) {
		collectedPreview.classList.add("selected");
	}

	body.classList.add("opened", "waiting");

	main.insertAdjacentHTML("beforeend", `<div class="loader window"></div>`);

	const tempUrl = url.split(".com")[1];
	if (tempUrl) {
		ga("set", "page", tempUrl);
		ga("send", "pageview");
	}

	setTimeout(function() {
		main.classList.add("has-loader");
		createFolder(url, slug, push, over);
	});
};

const createFolder = (url, slug, push, over) => {
	if (push) {
		const data = {
			action: "up",
			slug: slug
		};
		history.pushState(data, document.title, url);
	}
	$.ajax({
		url: url,
		type: "POST",
		data: {
			"request": true
		},
		dataType: "html",
		error: function(jqXHR, status, err) {
			console.warn(jqXHR, status, err);
		},
		success: function(html, status, jqXHR) {
			main.insertAdjacentHTML("beforeend", `<div class="folder new">${html}</div>`);
			const folder = main.querySelector(".folder.new");

			setupFolderData(folder);

			if (body.classList.contains("collection")) {
				replacePagination(folder);
			}

			const rotatables = folder.querySelectorAll(".rotate");
			rotatables.forEach((rotatable) => {
				shiftAndRotate(rotatable);
			});
		}
	});
};

const loadFolder = (folder) => {
	setupColumns(folder);
	setupZoomables();
	setupPagination();
	const slug = folder.dataset.slug,
		chapter = table.dataset.chapter,
		preview = grid.querySelector(`.preview[data-slug="${slug}"]`),
		collectedPreview = collection.querySelector(`.preview[data-slug="${slug}"]`);
	// folder.classList.add("loaded");
	if (collectedPreview) {
		collectedPreview.classList.add("selected");
		folder.querySelector("button.collect");
		folder.classList.remove("add")
		folder.classList.add("remove");
	}

	body.classList.remove("waiting");

	loadImages(folder);
	// folder.addEventListener("progress", function(inst, image) {
	//FIX: get parent preview
	// const preview = $(image.img).parents(".preview");
	// $preview.classList.add("loaded");
	// });

	const links = folder.querySelectorAll(".block a");
	links.forEach((link) => {
		const url = link.href,
			search = `/chapters/${chapter}/`;
		if (url.indexOf(search) <= -1) {
			link.setAttribute("target", "_blank");
		} else {
			link.classList.add("preview");
		}
	});

	const closeButton = folder.querySelector(".close-folder");
	closeButton.addEventListener("click", () => {
		closeFolder(slug, true);
	});
};

const scrollToPreview = (preview) => {
	const previewBounds = preview.getBoundingClientRect(),
		centerLeft = previewBounds.left - (window.innerWidth / 2) + (previewBounds.width / 2);
	body.scrollTo({
		left: centerLeft + body.scrollLeft
	});
};

const setupFolderData = (folder) => {
	const data = folder.querySelector(".data"),
		title = data.dataset.title,
		slug = data.dataset.slug,
		type = data.dataset.type,
		url = data.dataset.url,
		thumb = data.dataset.thumb;

	folder.dataset.title = data.dataset.title;
	folder.dataset.slug = data.dataset.slug;
	folder.dataset.type = data.dataset.type;
	folder.dataset.url = data.dataset.url;

	const ogTitle = document.querySelector("meta[property=\"og:title\"]");
	if (ogTitle) {
		ogTitle.innerHTML = `Mapping The Spirit — ${title}`;
		ogTitle.setAttribute("content", `Mapping The Spirit — ${title}`);
	}
	const ogImage = document.querySelector("meta[property=\"og:image\"]");
	if (ogImage) {
		ogImage.setAttribute("content", thumb);
	}
	const ogUrl = document.querySelector("meta[property=\"og:url\"]");
	if (ogUrl) {
		ogUrl.setAttribute("content", url);
	}

	const folderTitleLink = folderTitle.querySelector("a");
	folderTitle.classList.add("ready");
	folderTitleLink.innerHTML = title;
	folderTitleLink.href = url;
	folderTitle.classList.add("show");
	loadFolder(folder);
	folder.classList.add("open");
	setTimeout(() => {
		main.classList.remove("has-loader");
		const oldFolder = document.querySelector(".folder:not(.new)");
		if (oldFolder) {
			oldFolder.remove();
		}
		folder.classList.remove("new");
	}, openDur);
	data.remove();
};

const replacePagination = (folder) => {
	const slug = folder.dataset.slug,
		preview = grid.querySelector(`.preview[data-slug="${slug}"]`),
		prevArrow = folder.querySelector(".paginate.prev"),
		nextArrow = folder.querySelector(".paginate.next"),
		nextItem = $(preview).next("#grid .preview"),
		prevItem = $(preview).prev("#grid .preview");
	if (nextItem.length) {
		const nextUrl = nextItem[0].dataset.url,
			nextSlug = nextItem[0].dataset.slug;
		nextArrow.href = url;
		nextArrow.dataset.slug = slug;
	} else {
		nextArrow.remove();
	}
	if (prevItem.length) {
		const prevUrl = prevItem[0].dataset.url,
			prevSlug = prevItem[0].dataset.slug;
		prevArrow.href = url;
		prevArrow.dataset.slug = slug;
	} else {
		prevArrow.remove();
	}
};

const closeFolder = (slug, push) => {
	const folder = document.querySelector(`.folder[data-slug="${slug}"]`),
		url = table.dataset.url;
	if (push) {
		const data = {
			action: "down",
			slug: slug
		};
		history.pushState(data, document.title, url);
	}
	const collected = document.querySelector(`#collection .preview[data-slug="${slug}"]`),
		scrollLeft = $(table).scrollLeft();
	if (collected) {
		collected.classList.remove("selected");
	}
	setTimeout(() => {
		folder.remove();
		if (!document.querySelector(".folder")) {
			folderTitle.classList.remove("ready");
			body.classList.remove("opened");
		}
	}, closeDur);
	folder.classList.remove("open");

	const openSingles = document.querySelectorAll(".folder.open");
	if (!openSingles.length) {
		folderTitle.classList.remove("show");
		document.title = `Mapping The Spirit — ${main.dataset.title}`;
	} else {
		const newSingle = openSingles[openSingles.length - 1];
		document.title = `Mapping The Spirit — ${newSingle.dataset.title}`;
		folderTitle.querySelector("a").setAttribute("href", newSingle.dataset.url);
	}
};

const closeFolders = (e) => {
	const folders = document.querySelectorAll(".folder");
	if (!folders) {
		e.preventDefault();
	}
	closeFrame();
	folders.forEach((folder, i) => {
		setTimeout(function() {
			const slug = folder.dataset.slug;
			if (slug) {
				closeFolder(slug, true);
			}
		}, i * 10);
	});
};

const folderCloseButtons = document.querySelectorAll(".close-folders");
folderCloseButtons.forEach((clickFolderCloseButton) => {
	clickFolderCloseButton.addEventListener("click", closeFolders);
});

const resizeColumns = () => {
	const columns = document.querySelectorAll("article.columns");
	columns.forEach((folder) => {
		const leftSection = folder.querySelector(".folder-column.left"),
			rightSection = folder.querySelector(".folder-column.right"),
			windowWidth = window.innerWidth,
			leftWidth = leftSection.scrollWidth,
			leftPadding = leftSection.style.paddingRight,
			rightWidth = windowWidth - leftWidth,
			fontFactor = windowWidth * 2 * 19;

		rightSection.style.width = rightWidth + "px";

		// const columns = folder.querySelectorAll("section");
		// columns.forEach((column) => {
		// 	const columnWidth = column.getBoundingClientRect().width,
		// 				fontSize = columnWidth / windowWidth * 2 * 19;
		// 	if(fontSize <= 25 && fontSize >= 9) {
		// 		const blocks = column.querySelectorAll(".block");
		// 		blocks.forEach((block) => {
		// 			block.style.fontSize = fontSize + "px";
		// 		});
		// 	}
		// });
	});
};

const setupColumns = (folder) => {
	const minWidth = window.innerWidth / 5,
		maxWidth = window.innerWidth - minWidth,
		left = folder.querySelector(".folder-column.left");
	// folder.classList.add("left");
	$(left).resizable({
		handles: "e",
		minWidth: minWidth,
		maxWidth: maxWidth,
		create: function(e, ui) {
			e.target.classList.add("resizable");
		},
		resize: function(e, ui) {
			resizeColumns();
		},
		start: function(e, ui) {
			// left.querySelector(".video").classList.add("noCursor");
		},
		stop: function(e, ui) {
			// left.querySelector(".video").classList.remove("noCursor");
		}
	});
};

const toggleFolder = () => {
	const column = document.querySelector(".folder-column"); /*GET RIGHT ONE*/
	if (!checkSize("phone")) {
		return;
	}
	if (column.classList.contains("left")) {
		column.classList.remove("left");
		column.classList.add("right");
	}
	if (column.classList.contains(".right")) {
		column.classList.remove("right");
		column.classList.add("left");
	}
};

const folderHandle = document.querySelector(".folder-column .handle");
if (folderHandle) {
	folderHandle.addEventListener("click", toggleFolder);
}

const hoverFolderPreview = (slug) => {
	if (grid.classList.contains("dragging")) {
		return;
	}
	if (collection.classList.contains("sorting")) {
		return;
	}
	const preview = document.querySelector(`#grid .preview[data-slug="${slug}"]`),
		collected = document.querySelector(`#collection .preview[data-slug="${slug}"]`);
	if (preview) {
		preview.classList.add("opened");
	}
	if (collected) {
		collected.classList.add("opened");
	}
};

const unhoverFolderPreview = (slug) => {
	const preview = document.querySelector(`#grid .preview[data-slug="${slug}"]`),
		collected = document.querySelector(`#collection .preview[data-slug="${slug}"]`);
	if (preview) {
		preview.classList.add("opened");
	}
	if (collected) {
		collected.classList.add("opened");
	}
};

const updateStoryBegin = () => {
	const scrollToButtonOpacity = Math.round((1 - body.scrollLeft / window.innerHeight) * 100) / 100;
	if (scrollToButtonOpacity > 0) {
		scrollToButton.style.pointerEvents = "all";
		scrollToButton.style.opacity = scrollToButtonOpacity;
	} else {
		scrollToButton.style.pointerEvents = "none";
	}
}

document.querySelectorAll(".preview.click").forEach((preview) => {
	const slug = preview.dataset.slug;
	preview.addEventListener("mousemove", () => {
		hoverFolderPreview(slug);
	});
	preview.addEventListener("mouseleave", () => {
		unhoverFolderPreview(slug);
	});
});

const updateShiftables = (e) => {
	shiftables.forEach((shiftable) => {
		shiftAndRotate(shiftable, e);
	});
};

const shiftAndRotate = (elem, e) => {
	if (elem.classList.contains("helper")) {
		return;
	}
	const index = elem.dataset.index,
		shift = elem.dataset.shift || .5,
		rotate = elem.dataset.rotate || 0;
	const winWidth = window.innerWidth,
		winHeight = window.innerHeight;
	if (e) {
		x = e.clientX;
		y = e.clientY;
	} else {
		x = winWidth / 2;
		y = winHeight / 2;
	}
	const shiftX = -x / winWidth * shift,
		shiftY = -y / winHeight * shift;
	elem.style.transform = `translate(${shiftX}, ${shiftY}) rotate3d(0,0,1,${rotate}deg)`;
};


// if(body.classList.contains("opened")) {
// 	console.log("SCROLL");
// 	scrollToPreview(preview);
// }

const clickLink = (e) => {
	e.preventDefault();
	const url = this.href,
		parsed = url.split("/"),
		slug = parsed[parsed.length - 1],
		preview = grid.find(`.preview[data-slug="${slug}"]`);
	if (preview) {
		openFolder(slug, true, true);
	}
};

const blockLinks = document.querySelectorAll(".folder .block a.preview");
blockLinks.forEach((blockLink) => {
	blockLink.addEventListener("click", clickLink);
});

// const clipboard = new Clipboard(".button-copy");
// clipboard.on("success", function(e) {
// 	const button = e.trigger,
// 		text = button.innerText,
// 		citation = document.querySelector(".footer-citation"); //GET THE RIGHT ONE
// 	button.disabled = true;
// 	setTimeout(function() {
// 		button.disabled = false;
// 	}, 3000);
// });

const copyText = async (e) => {
	const button = e.target
	button.disabled = true
	try {
		const text = button.getAttribute("data-copy")
		await navigator.clipboard.writeText(text);
	} catch (error) {
		console.error(error.message);
	}
	setTimeout(function() {
		button.disabled = false;
	}, 3000);
}

const copyButtons = document.querySelectorAll(".button-copy");
copyButtons.forEach((copyButton) => {
	copyButton.addEventListener("click", copyText);
});



const showCite = (e) => {
	const block = $(this).parents(".block"), //GET THE RIGHT ONE
		citation = block[0].querySelector(".citation");
	citation.classList.toggle("show");
	if (citation.classList.contains("show")) {
		this.innerHTML = "Hide citation";
	} else {
		this.innerHTML = "Show citation";
	}
};

const citeToggle = document.querySelector(".folder .block .cite.show");
if (citeToggle) {
	citeToggle.addEventListener("click", showCite);
}

const browserNav = (e) => {
	e.preventDefault();
	const state = history.state;
	if (grid) {
		return;
	}
	// if(state) {
	// 	const action = state.action,
	// 				slug = state.slug;
	// 	if(action === "up") {
	// 		preview = grid.querySelector(`.preview[data-slug="${slug}"]`);
	// 		openFolder(slug, false, false);
	// 	}
	// 	if(action === "down") {
	// 		closeFolder(slug, false, false);
	// 	}
	// } else {
	// 	closeFolders(e);
	// }
};

// const clickFolderCloseButton = (e) => {
// 	e.preventDefault();
// 	const previewElem = document.querySelector(".folder"), /*GET ITEM ELEMENT*/
// 				previewSlug = previewElem.dataset.slug;
// 	closeFolder(previewSlug, true);
// };

// const folderCloseButtons = document.querySelectorAll(".close-folder");
// folderCloseButtons.forEach((folderCloseButton) => {
// 	folderCloseButton.addEventListener("click", clickFolderCloseButton);	
// })


const clickCollectButton = (button) => {
	const folder = $(button).parents(".folder")[0],
		/*GET ITEM ELEMENT*/
		slug = folder.dataset.slug;
	if (button.classList.contains("add")) {
		collectItem(slug, true);
		collection.querySelector(`[data-slug="${slug}"]`).classList.add("selected");
		button.classList.remove("add");
		button.classList.add("remove");
	} else {
		button.classList.remove("remove");
		button.classList.add("add");
		uncollectItem(slug);
	}
};

const collectButtons = document.querySelectorAll("button.collect");
collectButtons.forEach((button) => {
	button.addEventListener("click", () => {
		clickCollectButton(button);
	});
});


const loadCollection = () => {
	const chapter = table.dataset.chapter,
		collectionsStr = localStorage.getItem("mapping-the-spirit");
	if (collectionsStr) {
		collections = JSON.parse(collectionsStr);
		if (chapter) {
			insertCollection(collections[chapter], chapter);
		} else {
			collections.forEach((chapter, collectionObj) => {
				insertCollection(collectionObj, chapter);
			});
		}
	}
};

const insertCollection = (collectionObj, chapter) => {
	collectionObj.forEach((slug, i) => {
		const preview = table.querySelector(`[data-slug="${slug}"]`);
		if (preview) {
			collectItem(slug, false);
		} else if (slug) {
			createCollectionItem(slug, chapter, true);
			if (body.classList.contains("collection")) {
				createCollectionItem(slug, chapter, false, i);
			}
		}
	});
};

const createCollectionItem = (preview, chapter, inFooter, index) => {
	var url;
	url = `/api?preview=${preview}&chapter=${chapter}`;
	if (index) {
		url += `&index=${index}`;
	}
	if (inFooter) {
		url += `&footer=${inFooter}`;
	}
	$.ajax({
		url: url,
		dataType: "html",
		error: function(jqXHR, status, err) {
			console.warn(jqXHR, status, err);
		},
		success: function(preview, status, jqXHR) {
			if (!preview) {
				return;
			}
			addToCollection(preview, chapter, inFooter);
		}
	});
	if (body.classList.contains("aid")) {
		$(`.preview[data-slug="${preview}"]`).classList.add("collected");
	}
};

const addToCollection = (preview, chapter, inFooter) => {
	if (inFooter) {
		const previewsParent = document.querySelector("#collection .previews")
		previewsParent.appendChild(preview);
		collection.classList.remove("empty");
	} else {
		if (preview.classList.contains("text-lg")) {
			preview.classList.remove("text-lg");
			preview.classList.add("text-md");
		}
		grid.appendChild(preview);
		$(grid).isotope("appended", preview);
		$(grid).isotope("layout");
	}
	$(preview).imagesLoaded(function() {
		if (inFooter) {
			resizeCollection();
		}
	});
	loadImages();
};

const saveCollection = () => {
	const collectedPreviews = collection.querySelectorAll(".preview"),
		collectionObj = typeof collections === "object" ? collections : {},
		cleared = [];
	collectedPreviews.forEach((preview, i) => {
		const slug = preview.dataset.slug,
			chapter = preview.dataset.chapter;
		if ($.inArray(chapter, cleared) < 0) {
			collectionObj[chapter] = [];
			cleared.push(chapter);
		}
		if ($.inArray(slug, collectionObj[chapter]) < 0) {
			collectionObj[chapter].push(slug);
		}
	});
	const newCollectionStr = JSON.stringify(collectionObj);
	localStorage.setItem("mapping-the-spirit", newCollectionStr);
	resizeCollection();
};

const removeCollectedItem = (chapter, preview) => {
	const collectionObj = typeof collections === "object" ? collections : {},
		index = $.inArray(preview, collectionObj[chapter]);
	if (index >= 0) {
		delete collectionObj[chapter][index];
		const newCollectionStr = JSON.stringify(collectionObj);
		localStorage.setItem("mapping-the-spirit", newCollectionStr);
	}
};

const browseCollection = (e) => {
	if (collection.classList.contains("over")) {
		return;
	}
	if (collection.classList.contains("sorting")) {
		return;
	}
	const winWidth = window.innerWidth,
		collectionWidth = collectionPreviewWrapper.innerWidth;
	if (winWidth > collectionWidth) {
		return;
	}
	const mouse = e.clientX,
		mouseShift = collectionWidth * ((winWidth / 2 - mouse) / winWidth) * 1.1,
		offset = $(window).width() / 2;
	let x = -collectionWidth / 2 + offset + mouseShift + mouse - winWidth / 2;
	if (x > 0) {
		x = 0;
	}
	collectionPreviewWrapper.style.transform = `translateX(${x}px)`;
};
// const collection = document.querySelector("#collection");
if (collection) {
	collection.addEventListener("mousemove", browseCollection);
}


const resizeCollection = () => {
	if (!collection) {
		return;
	}
	const winWidth = window.innerWidth,
		previews = collection.querySelectorAll(".preview");
	let width = 0;
	previews.forEach((preview) => {
		width += preview.getBoundingClientRect().width;
	});
	if (width < winWidth) {
		width += 10;
	}
	return;
	//FIX: what is collectionPreviewWrapper?
	collectionPreviewWrapper.style.width = width + "px";
	if (width < winWidth) {
		$collectionPreviewWrapper.css({
			x: 0
		});
	}
};


const zoomImage = (img) => {
	const block = $(img).parents(".block")[0], //GET RIGHT ONE
		figcaption = block.querySelector("figcaption"),
		src = img.dataset.full || img.getAttribute("src");

	body.insertAdjacentHTML("beforeend",
		`<div id="frame" class="image">
			<div class="buttons">
				<button class="button-icon close"></button>
				<button class="button-icon in"></button>
				<button class="button-icon out"></button>
			</div>
			<div id="frame-inner" class="has-loader">
				<div class="window loader">
				</div>
			</div>
		</div>
		<div id="frame-bar">
			<div class="button expand"></div>
		</div>`);

	// $frame.prepend($buttons);
	// $bar.append($text);
	// if(!$text.find(".caption") || !$text.find(".caption").text().length) {
	// 	$bar.classList.add("no-caption");
	// }
	// body.append($frame).append($bar);

	const frameButtons = document.querySelectorAll("#frame button");
	frameButtons.forEach((frameButton) => {
		frameButton.addEventListener("click", (e) => {
			clickFrameTool(frameButton);
		});
	});


	const frame = document.querySelector("#frame"),
		frameBar = document.querySelector("#frame-bar");

	if (figcaption) {
		const textClone = figcaption.cloneNode();
		frameBar.append(textClone);
		if (!textClone.querySelector(".caption") || !textClone.querySelector(".caption").innerText.length) {
			frameBar.classList.add("no-caption");
		}
	} else {
		frameBar.classList.add("no-caption");
	}

	frame.classList.add("show");
	frameBar.classList.add("show");

	const newImg = new Image();
	newImg.onload = () => {
		const imgWidth = newImg.naturalWidth,
			imgHeight = newImg.naturalHeight,
			height = frame.getBoundingClientRect().height,
			width = (height / imgHeight) * imgWidth;
		window.frameMap = L.map("frame-inner", {
			minZoom: 2,
			maxZoom: 4,
			zoom: 1,
			center: [0, 0],
			zoomControl: false,
			crs: L.CRS.Simple,
			attributionControl: false
		});
		const southWest = frameMap.unproject([0, height], frameMap.getMaxZoom() - 1),
			northEast = frameMap.unproject([width, 0], frameMap.getMaxZoom() - 1),
			bounds = new L.LatLngBounds(southWest, northEast);
		L.imageOverlay(src, bounds).addTo(frameMap);
		frameMap.fitBounds(bounds);
		frameMap.setMaxBounds(bounds);
		setTimeout(function() {
			frame.classList.add("loaded");
		});
	};
	newImg.src = src;
};

const setupZoomables = () => {
	const zoomableImgs = document.querySelectorAll(".block-type-image:not(.no-zoom) img");
	zoomableImgs.forEach((img) => {
		img.addEventListener("click", () => {
			zoomImage(img);
		});
	});
};

const clickFrameTool = (button) => {
	const frame = document.querySelector("#frame"),
		frameBar = document.querySelector("#frame-bar");

	if (button.classList.contains("out")) {
		frame.zoomOut();
	} else if (button.classList.contains("in")) {
		frame.zoomIn();
	} else if (button.classList.contains("close")) {
		closeFrame();
	} else if (button.classList.contains("expand")) {
		frameBar.classList.toggle("toggled");
		resizeFrameBar(button);
	}
};

const closeFrame = () => {
	const frame = document.querySelector("#frame"),
		frameBar = document.querySelector("#frame-bar");
	if (frame) {
		setTimeout(() => {
			frame.remove();
			frameBar.remove();
		}, closeDur);
		frame.classList.remove("show");
		frameBar.classList.remove("show");
	}
};

const resizeFrameBar = (button) => {
	const frameBar = document.querySelector("#frame-bar");
	if (frameBar) {
		if (frameBar.classList.contains("toggled")) {
			// const height = frameBar.querySelector("figcaption").getBoundingClientRect().height;
		} else {
			// const height = frameBar.querySelector(".meta").getBoundingClientRect().height;
		}
		// frameBar.style.height = height + "px";
	}
};

const articles = document.querySelectorAll("article a");
articles.forEach((article) => {
	const articleLinks = article.querySelectorAll("a");
	articleLinks.forEach((articleLink) => {
		articleLink.addEventListener("mouseover", (e) => {
			article.classList.add("hover");
		});
		articleLink.addEventListener("mouseleave", (e) => {
			article.classList.remove("hover");
		});
	});
});

const toggleHeaderMenu = () => {
	header.classList.toggle("toggled");
};

const headerToggle = document.querySelector("#main-header .burger");
if (headerToggle) {
	headerToggle.addEventListener("click", toggleHeaderMenu);
}

const goBack = (e) => {
	e.preventDefault();
	window.history.back();
};

const errorBackButton = document.querySelector("#error a.back");
if (errorBackButton) {
	errorBackButton.addEventListener("click", goBack);
}


const checkSize = (check) => {
	const size = body.style.content.replace(/\"/g, "");
	if (!check) {
		return size;
	} else if (size === check) {
		return true;
	} else {
		return false;
	}
};

// const noHover = () => {
// 	const touch = indexOf.call(document.documentElement, "ontouchstart") >= 0 || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
// 	if(!touch) {
// 		return;
// 	}
// 	try {
// 		const ref = document.styleSheets,
// 					results = [];
// 		for (j = 0, len = ref.length; j < len; j++) {
// 			const si = ref[j];
// 			styleSheet = document.styleSheets[si];
// 			if(styleSheet.rules) {
// 				break;
// 			}
// 			ri = styleSheet.rules.length - 1;
// 			results.push((function() {
// 				var results1;
// 				results1 = [];
// 				while (ri >= 0) {
// 					if(styleSheet.rules[ri].selectorText) {
// 						break;
// 					}
// 					if(styleSheet.rules[ri].selectorText.match(":hover")) {
// 						styleSheet.deleteRule(ri);
// 					}
// 					results1.push(ri--);
// 				}
// 				return results1;
// 			})());
// 		}
// 		return results;
// 	} catch (undefined) {

// 	}
// };

window.addEventListener("load", (e) => {
	setup();
});
